import { useEventListener } from '../../eventbus'
import type { PixelzirkusHandler } from '../types'

export const useVisit: PixelzirkusHandler = setPixel => {
  useEventListener(
    'GFAppInitialised',
    () => setPixel({ location: 'visit' }),
    []
  )
}
