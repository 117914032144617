import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Outlet } from 'react-router-dom'
import { Loca } from '@gameforge/loca/react'
import { useEventDispatch } from './core'
import { checkboxAnimation } from './validation'

const useStyles = createUseStyles({
  '@keyframes shakeCheckbox': checkboxAnimation,
  page: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& form, & form label': {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      zIndex: '9'
    },
    '& form label': {
      '&.inline': {
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: '1.071rem',
        marginTop: '1.429rem',
        '& input': {
          margin: '0 0.714rem 0 0'
        }
      }
    },
    '& button': {
      background: 'linear-gradient(180deg, #00b7ff 0%, #00a3ff 100%)',
      border: '0.071rem #00c4ff solid',
      color: '#fff',
      minHeight: '2.857rem',
      outline: 'none',
      marginBottom: '2.143rem',
      width: '100%',
      overflowWrap: 'break-word',
      cursor: 'pointer',
      '&:hover': {
        backgroundImage: 'linear-gradient(180deg, #40C4FF 0%, #40C3FF 100%)',
        borderColor: 'rgba(157,252,255,0.40)'
      },
      '&:active:not(:disabled)': {
        backgroundImage: 'linear-gradient(180deg, #006FA8 0%, #007DBE 100%)',
        borderColor: 'rgba(255,255,255,0.15)',
        transform: 'scale(0.95, 0.95)'
      },
      '&:disabled': {
        backgroundImage: 'linear-gradient(180deg, #50A3C4 0%, #719EB8 100%)',
        borderColor: 'rgba(0,248,255,0.40)'
      }
    },
    '& label input': {
      backgroundColor: 'transparent',
      borderWidth: 0,
      color: '#fff',
      outline: 0
    },
    '& label > input': {
      display: 'block'
    },
    '& label > input, label > [role="input"], label div > [role="input"]': {
      backgroundColor: '#273748',
      borderWidth: '0 0 0.143rem 0',
      borderColor: '#00b7ff',
      borderStyle: 'solid',
      height: '2.5rem',
      outline: '0',
      margin: '1rem 0 2.143rem',
      display: 'flex',
      '& > input': {
        flex: '1 0'
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield'
      },
      '&[type=checkbox]': {
        appearance: 'none',
        backgroundColor: 'transparent',
        border: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '&:after': {
          content: '""',
          display: 'block',
          height: '1rem',
          width: '1rem',
          lineHeight: '1rem',
          fontSize: '1rem',
          color: '#000',
          textAlign: 'center',
          borderRadius: '0.143rem',
          border: '0.071rem #00b7ff solid'
        },
        '&.error:after': {
          animation: '$shakeCheckbox 2.2s ease'
        },
        '&:checked:after': {
          content: '"✔"',
          color: '#000',
          backgroundColor: '#00b7ff'
        }
      }
    },
    '& input+small, & [role="input"]+small': {
      position: 'relative',
      height: 0,
      top: '-2rem',
      '&.note': {
        textAlign: 'right'
      }
    },
    '& .separator': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&:before, &:after': {
        content: '""',
        borderBottom: '0.071rem #262d36 solid',
        display: 'block',
        height: '0.071rem',
        flexGrow: 1
      },
      '&:before': {
        marginRight: '2.857rem'
      },
      '&:after': {
        marginLeft: '2.857rem'
      }
    },
    '& button[type=submit] + p': {
      textAlign: 'center'
    },
    '& a': {
      color: '#00B7FF',
      cursor: 'pointer',
      textDecoration: 'none'
    }
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '44.7rem',
    padding: '2.5rem 2.857rem 0',
    '@media (min-width: 28.571rem)': {
      width: '28.571rem'
    }
  },
  copyright: {
    display: 'block',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    height: '2.85rem',
    fontSize: '0.714rem',
    textAlign: 'center',
    marginTop: 'auto',
    paddingBottom: '1.643rem'
  }
})

export const Layout: React.FC = () => {
  const classes = useStyles()
  const dispatchAppInitialisedEvent = useEventDispatch('GFAppInitialised')

  useEffect(() => dispatchAppInitialisedEvent(), [])

  return (
    <>
      <div className={classes.page}>
        <div className={classes.pageContent}>
          <Outlet />
        </div>
      </div>
      <div className={classes.copyright}>
        <Loca
          id="Gameforge.CopyRights"
          as="small"
          values={{ year: new Date().getFullYear() }}
        />
      </div>
    </>
  )
}
