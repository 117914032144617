import React, { useCallback, useState } from 'react'
import { apiClient, Loca } from '../core'
import { useUser } from '../providers'

export const SignOutLink: React.FC = () => {
  const [busy, setBusy] = useState(false)
  const { refreshUser } = useUser()
  const signOut = useCallback(() => {
    if (busy) return

    setBusy(true)
    void apiClient
      .delete('/sessions/current')
      .catch(() => {
        console.error('Sign out call failed')
      })
      .then(() => {
        setBusy(false)

        return refreshUser()
      })
  }, [busy, setBusy, refreshUser])

  return (
    <a onClick={signOut}>
      <Loca id="Generic.SignOut" /> ➔
    </a>
  )
}
