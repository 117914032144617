import React, { StrictMode } from 'react'
import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import {
  BrowserRouter,
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import { App } from './App'
import { BlackboxProvider, TranslationsProvider } from './core'

Sentry.init({
  dsn: 'https://fb9a184a01914626a42823375c2e54e6@crashreporting.gameforge.com/21',
  release: `oauth-ui@${process.env.VERSION || /* istanbul ignore next */ ''}`,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    }),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
      networkDetailAllowUrls: [new RegExp(`${window.origin}\\/api\\/.*`)],
      networkCaptureBodies: false,
      networkResponseHeaders: ['x-request-id']
    })
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

const rootEl = document.getElementById('root')
if (rootEl === null) throw new Error('Root element is missing in document')
const root = createRoot(rootEl)

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

root.render(
  <StrictMode>
    <Sentry.ErrorBoundary>
      <TranslationsProvider>
        <BlackboxProvider>
          <BrowserRouter>
            <SentryRoutes>
              <Route path="*" element={<App />} />
            </SentryRoutes>
          </BrowserRouter>
        </BlackboxProvider>
      </TranslationsProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>
)
