import { useLocation } from 'react-router-dom'

export const useLocationState = <
  State extends Record<string, unknown> = Record<string, unknown>
>(): State | undefined => {
  const location = useLocation()

  return typeof location?.state === 'object'
    ? (location.state as State)
    : undefined
}
