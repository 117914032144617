import React from 'react'
import { SignOutLink } from '../components'
import { Loca } from '../core'
import { useUser } from '../providers'
import { Navigate, useOAuthSearchParams } from '../router'

export const Overview: React.FC = () => {
  const { user } = useUser()
  const oauthSearchParams = useOAuthSearchParams()

  if (user === null) return <Navigate to="sign-in" />
  if (user.banned) return <Navigate to="banned" />
  if (oauthSearchParams) return <Navigate to="redirect" />

  return (
    <>
      <Loca as="h1" id="Overview.Title" />
      <Loca as="p" id="Overview.Description" values={{ email: user.email }} />
      <SignOutLink />
    </>
  )
}
