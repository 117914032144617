import { useCallback } from 'react'
import { useLocale, useVisit } from './handlers'
import type { SetPixel } from './types'

declare global {
  interface Window {
    setPixel?: (meta: Record<string, unknown>) => void
    pixelzirkus?: {
      setDefaultLanguage?: (language: string) => void
    }
  }
}

export const usePixelzirkus = () => {
  const setPixel: SetPixel = useCallback(
    (meta: Record<string, unknown>) =>
      window.setPixel?.({
        page: window.location.href,
        ...meta
      }),
    []
  )

  useLocale()
  useVisit(setPixel)
}
