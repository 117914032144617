import React from 'react'
import { createUseStyles } from 'react-jss'
import { Navigate, Route } from 'react-router-dom'
import { IntlRoutes, useCookieConsent, usePixelzirkus } from './core'
import { Layout } from './Layout'
import { ApplicationRedirect, Banned, Error, Overview, SignIn } from './pages'
import { Providers } from './providers'
import './fonts'

const useStyles = createUseStyles({
  '@global *': {
    boxSizing: 'border-box'
  },
  '@global html,body,#root': {
    width: '100%',
    height: '100%'
  },
  '@global html': {
    color: '#c6c6c6',
    backgroundColor: '#010913'
  },
  '@global #root': {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '47rem',
    minWidth: '28.571rem',
    background: 'linear-gradient(180deg, #042142 0%, #010913 57%)'
  }
})

export const App: React.FC = () => {
  useStyles()
  usePixelzirkus()
  useCookieConsent()

  return (
    <Providers>
      <IntlRoutes>
        <Route element={<Layout />}>
          <Route path="" element={<Overview />} />
          <Route path="banned" element={<Banned />} />
          <Route path="error" element={<Error />} />
          <Route path="sign-in/*" element={<SignIn />} />
          <Route path="redirect" element={<ApplicationRedirect />} />
          <Route path="*" element={<Navigate to="./" />} />
        </Route>
      </IntlRoutes>
    </Providers>
  )
}
