import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from '../../components'
import { Loca } from '../../core'
import { useSignIn } from './hooks'

export const SignInForm: React.FC = () => {
  const { busy, credentials, error, signIn } = useSignIn()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: credentials?.email || process.env.SIGN_IN_EMAIL || '',
      password: process.env.SIGN_IN_PASSWORD || '',
      rememberMe: false
    }
  })

  return (
    <>
      <Loca as="h1" id="Generic.SignIn" />
      <form onSubmit={handleSubmit(signIn)}>
        <Form.Label errors={error} errorMessageId={'SignIn.Error'}>
          <Form.Email.Input
            error={errors.email}
            {...register('email', {
              required: 'Email.Required',
              validate: Form.Email.validate
            })}
          />
        </Form.Label>

        <label>
          <Loca id="Generic.Password" />
          <Form.Password.Input {...register('password', { required: true })} />
        </label>

        <label className="inline">
          <input type="checkbox" {...register('rememberMe')} />
          <Loca id="SignIn.RememberMe" />
        </label>

        <Loca as="button" type="submit" disabled={busy} id="Generic.SignIn" />
      </form>
    </>
  )
}
