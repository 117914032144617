import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { BackLink, Form } from '../../components'
import { Loca } from '../../core'
import { useLocationState, useNavigate } from '../../router'

export const TwoFactorCode: React.FC = () => {
  const navigate = useNavigate()
  const { error, ...state } = useLocationState() || {}

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      otpCode: ''
    }
  })

  const submitCode = useCallback(
    (args: { otpCode: string }) => {
      navigate('..', { state: { ...state, ...args } })
    },
    [state, navigate]
  )

  return (
    <div>
      <BackLink />
      <Loca as="h1" id="TwoFactorAuthentication.Code.Title" />

      <Loca as="p" id="TwoFactorAuthentication.Code.Description" />

      <form onSubmit={handleSubmit(submitCode)}>
        <Form.Label
          errors={!!error}
          errorMessageId={'TwoFactorAuthentication.Code.InvalidCode'}
        >
          <Loca id="TwoFactorAuthentication.Code.Code" />
          <Form.Code.Input
            control={control}
            rules={{
              required: 'TwoFactorAuthentication.Code.RequiredCode',
              minLength: {
                value: 6,
                message: 'TwoFactorAuthentication.Code.EmptyCode'
              }
            }}
            name="otpCode"
            length={6}
            autoFocus
            error={errors.otpCode}
            alphanumeric="numbers"
          />
        </Form.Label>

        <Loca as="button" type="submit" id="Generic.Next" />
      </form>
    </div>
  )
}
