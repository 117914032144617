import React, { useRef, useLayoutEffect } from 'react'
import { usePrevious } from '../../../hooks'

export interface SingleCodeInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  focus?: boolean
}

export const CodeInputComponent = ({
  focus,
  autoFocus,
  ...props
}: SingleCodeInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const prevFocus = usePrevious(!!focus)

  useLayoutEffect(() => {
    if (!inputRef.current || !focus || !autoFocus) return

    inputRef.current.focus()
    focus !== prevFocus && inputRef.current.select()
  }, [autoFocus, focus, prevFocus])

  return <input ref={inputRef} role="input" autoComplete="off" {...props} />
}
