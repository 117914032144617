import { DependencyList, useCallback, useLayoutEffect, useState } from 'react'

export const useEventListener = <K extends keyof WindowEventMap>(
  event: K,
  listener: (e: WindowEventMap[K]) => void,
  deps: DependencyList = []
) => {
  const [registered, setRegistered] = useState(false)
  const listenerCallback = useCallback(listener, deps)

  useLayoutEffect(() => {
    window.addEventListener(event, listenerCallback)

    setRegistered(true)

    return () => window.removeEventListener(event, listenerCallback)
  }, [event, listenerCallback])

  return registered
}
