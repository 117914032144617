import React from 'react'
import {
  Navigate as ReactRouterNavigate,
  NavigateProps
} from 'react-router-dom'
import { useOAuthSearchParams } from '../hooks'
import { generateSearch, normalizeTo } from '../utils'

export const Navigate: React.FC<NavigateProps> = ({ to, ...props }) => {
  const oauthSearchParams = useOAuthSearchParams()
  const { search, ...rest } = normalizeTo(to)

  return (
    <ReactRouterNavigate
      {...props}
      to={{ ...rest, search: generateSearch(oauthSearchParams, search) }}
    />
  )
}
