import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useOAuthSearchParams = () => {
  const [searchParams] = useSearchParams()

  return useMemo<URLSearchParams | null>(() => {
    const response_type = searchParams.get('response_type')
    const client_id = searchParams.get('client_id')
    const state = searchParams.get('state')
    const scope = searchParams.get('scope')
    const redirect_uri = searchParams.get('redirect_uri')

    if (!response_type || !client_id || !redirect_uri) return null

    const oauthSearchParams = new URLSearchParams({
      response_type,
      client_id,
      redirect_uri
    })

    if (state) oauthSearchParams.append('state', state)
    if (scope) oauthSearchParams.append('scope', scope)

    return oauthSearchParams
  }, [searchParams])
}
