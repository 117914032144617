import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { NavigateProps, Navigate as NavigateBase } from 'react-router-dom'

export const Navigate: React.FC<NavigateProps> = ({ to, ...rest }) => {
  const { locale } = useIntl()
  const toWithLocale = useMemo(() => {
    const { pathname, ...rest } = typeof to === 'string' ? { pathname: to } : to

    const pathLocale = pathname?.split('/')[1]

    return pathLocale === locale
      ? { pathname, ...rest }
      : { pathname: `/${locale}/${pathname || ''}`, ...rest }
  }, [to, locale])

  return <NavigateBase to={toWithLocale} {...rest} />
}
