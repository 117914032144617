export const generateSearch = (
  oauthSearchParams: URLSearchParams | null,
  search: string | undefined
): string | undefined => {
  if (!oauthSearchParams) return search

  const searchParams = new URLSearchParams(oauthSearchParams.toString())
  new URLSearchParams(search).forEach((value, name) =>
    searchParams.set(name, value)
  )

  return `?${searchParams.toString()}`
}
