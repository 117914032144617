import React from 'react'
import { SupportLink, SignOutLink, BackLink } from '../components'
import { Loca } from '../core'
import { useUser } from '../providers'
import { Navigate, useLocationState } from '../router'

export const Banned: React.FC = () => {
  const { signInError } = useLocationState<{ signInError?: boolean }>() || {}
  const { user } = useUser()

  if (!signInError && !user?.banned) return <Navigate to=".." />

  return (
    <>
      {signInError ? <BackLink /> : null}
      <Loca as="h1" id="Banned.Title" />

      <Loca as="p" id="Banned.Description" />

      <Loca
        as="p"
        id="Banned.ContactSupport"
        links={{ support: SupportLink }}
      />

      <br />
      <br />
      <br />

      {!signInError ? <SignOutLink /> : null}
    </>
  )
}
