import { useCallback } from 'react'
import {
  useNavigate as useReactRouterNavigate,
  NavigateFunction,
  To,
  NavigateOptions
} from 'react-router-dom'
import { generateSearch, normalizeTo } from '../utils'
import { useOAuthSearchParams } from './useOAuthSearchParams'

export const useNavigate = (): NavigateFunction => {
  const navigate = useReactRouterNavigate()
  const oauthSearchParams = useOAuthSearchParams()

  return useCallback<NavigateFunction>(
    (to: To | number, options: NavigateOptions = {}) => {
      if (typeof to === 'number') {
        navigate(to)

        return
      }

      const { search, ...rest } = normalizeTo(to)
      navigate(
        { ...rest, search: generateSearch(oauthSearchParams, search) },
        options
      )
    },
    [navigate]
  )
}
