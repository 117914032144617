import React from 'react'
import { Loca } from '../core'
import { useSearchParams, Navigate } from '../router'

export const Error: React.FC = () => {
  const [searchParams] = useSearchParams()
  const error = searchParams.get('error')

  if (!error) return <Navigate to=".." />

  return (
    <>
      <Loca as="h1" id="Error.Title" />
      <Loca as="p" id="Error.Description" values={{ error }} />
    </>
  )
}
