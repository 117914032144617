import React, { useState, useCallback, useEffect } from 'react'
import {
  Control,
  FieldError,
  FieldPath,
  FieldValues,
  RegisterOptions,
  useController
} from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Loca } from '../../../core'
import { CodeInputComponent } from './CodeInputComponent'

const useStyles = createUseStyles({
  code: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '& > input': {
      textAlign: 'center'
    },
    '& > input[type="number"]': {
      width: '3.5rem'
    },
    '& > input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
      {
        '-webkit-appearance': 'none',
        margin: '0'
      },
    '@media (max-width: 755px)': {
      '& > input[type="number"]': {
        width: '3.2rem'
      },
      '& > input[type="text"]': {
        width: '1.8rem'
      }
    },
    '& span': {
      position: 'absolute',
      bottom: '0.5rem',
      color: '#ea1d36'
    }
  }
})

export const CodeInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  error,
  name,
  control,
  rules,
  ...props
}: Omit<React.HTMLProps<HTMLInputElement>, 'name'> & {
  alphanumeric?: 'letters' | 'numbers' | 'both'
  autoFocus?: boolean
  disabled?: boolean
  error?: FieldError
  length: number
  name: TName
  control: Control<TFieldValues, unknown>
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >
}) => {
  const classes = useStyles()
  const { length, autoFocus, disabled, alphanumeric, ...rest } = props
  const [activeInput, setActiveInput] = useState(0)
  const [codeValues, setCodeValues] = useState(Array<string>(length).fill(''))

  const { field } = useController({
    name,
    control,
    rules
  })

  useEffect(() => {
    const value = codeValues.join('')
    if (value === '' && field.value === value) return

    field.onChange(value)
  }, [field.onChange, field.value, codeValues])

  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedCodeValues = [...codeValues]
      updatedCodeValues[activeInput] = str[0] || ''
      setCodeValues(updatedCodeValues)
    },
    [activeInput, setCodeValues]
  )

  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0)
      setActiveInput(selectedIndex)
    },
    [length]
  )

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1)
  }, [activeInput, focusInput])

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1)
  }, [activeInput, focusInput])

  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index)
    },
    [focusInput]
  )

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.currentTarget.value
      changeCodeAtFocus(val)
      focusNextInput()
    },
    [changeCodeAtFocus, focusNextInput]
  )

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key

      switch (pressedKey) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault()
          if (codeValues[activeInput]) {
            changeCodeAtFocus('')
          } else {
            focusPrevInput()
          }
          break
        }
        case 'ArrowLeft': {
          e.preventDefault()
          focusPrevInput()
          break
        }
        case 'ArrowRight': {
          e.preventDefault()
          focusNextInput()
          break
        }
        case 'ArrowDown': {
          e.preventDefault()
          break
        }
        case 'ArrowUp': {
          e.preventDefault()
          break
        }
        default: {
          if (alphanumeric === 'both' && /^[^a-z0-9]$/.exec(pressedKey))
            e.preventDefault()

          if (alphanumeric === 'numbers' && /^[^0-9]$/.exec(pressedKey))
            e.preventDefault()

          break
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, codeValues]
  )

  return (
    <>
      <div {...rest} className={classes.code}>
        {[...Array(length).keys()].map((index: number) => (
          <CodeInputComponent
            size={1}
            type={alphanumeric === 'numbers' ? 'number' : 'text'}
            value={codeValues && codeValues[index].toUpperCase()}
            key={index}
            autoFocus={autoFocus}
            focus={activeInput === index}
            onFocus={handleOnFocus(index)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            disabled={disabled}
          />
        ))}
        {error && <Loca as="span" id={error.message} />}
      </div>
    </>
  )
}
