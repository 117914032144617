import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { loadLocales } from './loadLocales'
import { loadTranslations, Translations } from './loadTranslations'

type TranslationsContextType = {
  locales: string[]
  getTranslations: (locale: string) => Promise<Translations>
}

const Context = createContext<TranslationsContextType>({
  locales: [],
  getTranslations: () => Promise.resolve({})
})

export const useTranslations = () =>
  useContext<TranslationsContextType>(Context)

export const TranslationsProvider: React.FC<
  React.PropsWithChildren
> = props => {
  const [locales, setLocales] = useState<string[] | undefined>()

  const getTranslations = useCallback(
    (locale: string) => loadTranslations(locale),
    []
  )

  useEffect(() => {
    void loadLocales().then(setLocales)
  }, [])

  if (!locales) return null

  return <Context.Provider {...props} value={{ locales, getTranslations }} />
}
