import { useEffect, useState } from 'react'
import { useLocation, useLocationState, useNavigate } from '../../../router'

export type Credentials = {
  email: string
  password: string
  otpCode?: string
  rememberMe?: boolean
}

export const useStateCredentials = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [credentials, setCredentials] = useState<Credentials | undefined>()

  const state = useLocationState<Partial<Credentials>>()

  useEffect(() => {
    if (!state?.email || !state.password) return

    const { otpCode, email, password, rememberMe } = state

    setCredentials({ otpCode, email, password, rememberMe })
    navigate(location.pathname, { replace: true, state: {} })
  }, [
    location.pathname,
    state?.otpCode,
    state?.email,
    state?.password,
    state?.rememberMe
  ])

  return credentials
}
