import React, { createContext, useCallback, useContext } from 'react'
import { useGss } from './gss'

const Context = createContext<() => Promise<string>>(() => Promise.resolve(''))

export const useBlackbox = () => useContext(Context)

export const BlackboxProvider: React.FC<React.PropsWithChildren> = props => {
  const blackboxFns = [useGss()]

  const getBlackbox = useCallback(() => {
    return Promise.all(blackboxFns.map(fn => fn()))
      .then(b => b.filter(Boolean))
      .then(b => b.join(','))
  }, [blackboxFns])

  return <Context.Provider {...props} value={getBlackbox} />
}
