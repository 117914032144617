import { useEventListener } from '../eventbus'

declare global {
  interface Window {
    gfCookieConsent?: {
      setLocale: (locale: string) => void
    }
  }
}

export const useCookieConsent = () => {
  useEventListener('GFLocaleChange', e =>
    window?.gfCookieConsent?.setLocale(e.detail.locale)
  )
}
