import React, { createContext, useContext, useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { LocaProvider as BaseLocaProvider } from '@gameforge/loca/react'
import { useEventDispatch } from '../eventbus'
import { Translations } from './loadTranslations'
import { useTranslations } from './TranslationsProvider'

type LocaContext = {
  locale: string
}

const Context = createContext<LocaContext>({ locale: 'en-GB' })
export const useLoca = () => useContext(Context)

type Props = {
  locale: string
}
export const LocaProvider: React.FC<Props> = props => {
  const { locale } = props
  const dispatchLocaleChangeEvent = useEventDispatch('GFLocaleChange')
  const { getTranslations } = useTranslations()
  const [messages, setMessages] = useState<Translations | undefined>()

  useEffect(() => {
    void getTranslations(locale).then(setMessages)
  }, [locale, getTranslations])

  useEffect(
    () => dispatchLocaleChangeEvent({ locale }),
    [locale, dispatchLocaleChangeEvent]
  )

  if (!messages) return null

  return (
    <Context.Provider value={{ locale }}>
      <BaseLocaProvider locale={locale} key={locale} messages={messages}>
        <Outlet />
      </BaseLocaProvider>
    </Context.Provider>
  )
}
