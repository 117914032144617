import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { parseLocale } from '@gameforge/loca'
import { useTranslations } from './TranslationsProvider'

const fallbackLocale = 'en-GB'

export const DefaultLocaleRedirect: React.FC = () => {
  const { pathname, search, hash } = useLocation()
  const { locales } = useTranslations()

  const navigatorLocale = parseLocale(
    (typeof navigator !== 'undefined' && navigator.language) || fallbackLocale
  ).short

  return (
    <Navigate
      to={{
        pathname: `/${
          locales.includes(navigatorLocale) ? navigatorLocale : fallbackLocale
        }${pathname}`,
        search,
        hash
      }}
    />
  )
}
