import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { useUser } from '../../providers'
import { Navigate } from '../../router'
import { TwoFactorAuthentication } from '../TwoFactorAuthentication'
import { SignInForm } from './SignInForm'

export const SignIn: React.FC = () => {
  const { user } = useUser()

  if (user) return <Navigate to=".." state={{}} />

  return (
    <Routes>
      <Route path="" element={<SignInForm />} />
      <Route path="2fa/*" element={<TwoFactorAuthentication />} />
    </Routes>
  )
}
