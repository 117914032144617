import React, { useEffect } from 'react'
import { Loca } from '../core'
import { useUser } from '../providers'
import { Navigate, useOAuthSearchParams } from '../router'

export const ApplicationRedirect: React.FC = () => {
  const oauthSearchParams = useOAuthSearchParams()
  const { user } = useUser()

  useEffect(() => {
    if (!user || user.banned || !oauthSearchParams) return

    window.location.replace(`/authorize?${oauthSearchParams.toString()}`)
  }, [oauthSearchParams, user])

  if (!user || user.banned || !oauthSearchParams) return <Navigate to=".." />

  return (
    <>
      <Loca as="h1" id="ApplicationRedirect.Title" />
      <Loca as="p" id="ApplicationRedirect.Description" />
    </>
  )
}
