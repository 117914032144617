import React from 'react'
import { RouteProps, Routes, Route } from 'react-router-dom'
import { DefaultLocaleRedirect } from './DefaultLocaleRedirect'
import { LocaProvider } from './LocaProvider'
import { useTranslations } from './TranslationsProvider'

type IntlRoutesProps = Pick<RouteProps, 'children'>

export const IntlRoutes: React.FC<IntlRoutesProps> = ({ children }) => {
  const { locales } = useTranslations()

  return (
    <Routes>
      {locales.map(locale => (
        <Route
          key={locale}
          path={`/${locale}`}
          element={<LocaProvider locale={locale} />}
          children={children}
        />
      ))}
      <Route path="/*" element={<DefaultLocaleRedirect />} />
    </Routes>
  )
}
