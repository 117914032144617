import { parseLocale } from '@gameforge/loca'
import { useEventListener } from '../../eventbus'

declare global {
  interface Window {
    setPixel?: (meta: Record<string, unknown>) => void
  }
}

export const useLocale = () => {
  useEventListener('GFLocaleChange', e => {
    const { locale } = e.detail
    const parsedLocale = locale && parseLocale(locale)

    if (!parsedLocale) return

    window?.pixelzirkus?.setDefaultLanguage?.(parsedLocale.gameforge)
  })
}
