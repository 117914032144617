import { useCallback } from 'react'
import { loadScript } from '../../../../utils'

export const useGss = () => {
  const getGame1Fn = useCallback(async () => {
    if (window.game1) return window.game1
    const src = `/gss/game1.js`

    await loadScript(src, document.body)

    return window.game1
  }, [])

  const generateBlackbox = useCallback(() => {
    return new Promise(resolve => {
      void getGame1Fn()
        .then(game1 => game1(b => resolve(b ? `${b}` : '')))
        .catch(() => {
          console.error('game1 is not defined')
          resolve('')
        })
    })
  }, [])

  return generateBlackbox
}

declare global {
  interface Window {
    game1: (callback: (blackbox: string) => void) => void
  }
}
