declare global {
  type GFAppInitialisedEvent = void
  type GFLocaleChangeEvent = { locale: string }

  interface WindowEventMap {
    GFAppInitialised: CustomEvent<GFAppInitialisedEvent>
    GFLocaleChange: CustomEvent<GFLocaleChangeEvent>
  }
}

export const dispatch = <T extends keyof WindowEventMap>(
  type: T,
  detail?: WindowEventMap[T] extends CustomEvent
    ? WindowEventMap[T]['detail']
    : never
) => {
  console.log('Dispatching', type, 'with', detail)
  window.dispatchEvent(new CustomEvent(type, { detail }))
}
