import React from 'react'
import { FieldError } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Loca } from '../../core'

const useStyles = createUseStyles({
  email: {
    '& span': {
      position: 'absolute',
      bottom: '0.5rem',
      color: '#ea1d36'
    }
  }
})

export const validate = (value: string) =>
  value.length > 0 && new RegExp('[^{^}]*@[^.]+\\..*$').test(value)

export const Input = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement> & {
    error?: FieldError
  }
>(({ error, ...props }, ref) => {
  const classes = useStyles()

  return (
    <>
      <Loca id="Generic.Email" />
      <div className={classes.email} role="input">
        <input {...props} ref={ref} type="email" />
        {error && error.type === 'validate' ? (
          <Loca as="span" id="Email.Invalid" />
        ) : (
          error && <Loca as="span" id={error.message} />
        )}
      </div>
    </>
  )
})
