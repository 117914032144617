import React from 'react'
import { createUseStyles } from 'react-jss'
import { Loca } from '../core'
import { useNavigate } from '../router'

const useStyles = createUseStyles({
  back: {
    marginBottom: '3rem'
  }
})

export const BackLink: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <a role="link" className={classes.back} onClick={() => navigate(-1)}>
      ❮ <Loca id="Generic.Back" />
    </a>
  )
}
