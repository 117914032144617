import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { apiClient } from '../core'

type User = {
  userId: string
  email: string
  banned: boolean
}

type UserContextType = {
  user: User | null
  refreshUser: () => Promise<void>
}

const Context = createContext<UserContextType>({
  user: null,
  refreshUser: () => Promise.resolve()
})

export const useUser = () => useContext<UserContextType>(Context)

export const UserProvider: React.FC<{ children?: React.ReactNode }> = props => {
  const [busy, setBusy] = useState<boolean>(false)
  const [user, setUser] = useState<User | null | undefined>()

  const refreshUser = useCallback(async () => {
    if (busy) return
    setBusy(true)

    try {
      setUser(await apiClient.get('/users/me').then(res => res.json<User>()))
    } catch (err: unknown) {
      setUser(null)
    } finally {
      setBusy(false)
    }
  }, [busy, setBusy])

  useEffect(() => {
    if (user !== undefined) return

    void refreshUser()
  }, [user, refreshUser])

  if (user === undefined) return null

  return <Context.Provider {...props} value={{ user, refreshUser }} />
}
