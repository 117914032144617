import React, { useCallback, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Eye, EyeSlash } from '../../icons'

const useStyles = createUseStyles({
  passwordInput: {
    '& > svg': {
      width: '1.286rem',
      margin: '0 0.714rem'
    }
  }
})

export const validate = (value: string) =>
  value.length >= 10 && /[A-Z]/.test(value) && /[0-9]/.test(value)

export const Input = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement> & {
    showValidationMessage?: boolean
    error?: FieldError
  }
>((props, ref) => {
  const classes = useStyles()
  const [visible, setVisible] = useState(false)
  const toggle = useCallback(() => setVisible(v => !v), [])

  return (
    <div className={classes.passwordInput} role="input">
      <input {...props} ref={ref} type={visible ? 'text' : 'password'} />
      {visible ? <Eye onClick={toggle} /> : <EyeSlash onClick={toggle} />}
    </div>
  )
})
